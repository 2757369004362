/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Image, Subtitle, Text, Button } from '@swp/components'
import SiteHeader from '../../components/de/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Ceník"}>
        <SiteHeader />

        <Column className="pb--60 pt--80" name={"einleitung-0"} style={{"backgroundColor":"var(--color-supplementary)"}} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s2 --center el--1 flex--center" anim={"2"} animS={"2"} columns={"1"}>
            
            <ColumnWrapper animS={"3"} style={{"maxWidth":800}}>
              
              <Title className="title-box" content={"<span style=\"color: var(--color-dominant);\">Presliste</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"presliste"} layout={"l13"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s3 --center el--3 flex--center" anim={"2"} animS={"3"} style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/169/img-1_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/169/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/169/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/169/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/169/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/169/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":360}} content={"Chimney Maintenance"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":360}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

              <Text className="text-box" style={{"maxWidth":360}} content={"17€"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/169/img-2_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/169/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/169/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/169/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/169/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/169/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":360}} content={"Chimney Cleaning"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":360}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

              <Text className="text-box" style={{"maxWidth":360}} content={"17€"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Image alt={"Bild anklicken und bearbeiten"} src={"https://cdn.swbpg.com/t/i/template/169/img-3_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes={"100vw"} style={{"maxWidth":1080}} srcSet={"https://cdn.swbpg.com/t/i/template/169/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/169/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/169/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/169/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/169/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" style={{"maxWidth":360}} content={"Mechanical Cleaning"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":360}} content={"Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten."}>
              </Text>

              <Text className="text-box" style={{"maxWidth":360}} content={"17€"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1nuglum css-42e4bw pb--60 pt--60" name={"kontakt"} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/169/fullscreen-2_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/169/fullscreen-2_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/169/fullscreen-2_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/169/fullscreen-2_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/169/fullscreen-2_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/169/fullscreen-2_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/169/fullscreen-2_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/169/fullscreen-2_s=3000x_.jpg);
    }
  
background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim7 --anim-s2 pb--16 pt--16" anim={"7"} animS={"2"}>
              
              <Title className="title-box" content={"<span style=\"color: white;\">+49 797 811 2X05<br>info@vase-stranky.com</span><br>"}>
              </Title>

              <Button className="btn-box fs--20" content={"Buchung"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" name={"partei"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex --center el--3 flex--center" anim={""} animS={"2"} style={{"maxWidth":""}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box text-box--left" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-dominant);\">Fangen Sie an Ihren Text zu schreiben. Dieser Bereich ist für Ihren Text bestimmt. Bei Bedarf können Sie die Farbe des Textblocks, die Schriftgröße und den Zeilenabstand ändern oder den Text ausrichten.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":205}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Pension Annamaria</span>"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-dominant);\">Erstellt mit <a href=\"https://saywebpage.com\">saywebpage.com</a></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-dominant);\">Oeder Weg 22<br>+49 797 811 2X05<br>info@vase-stranky.com</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}